import React, { useState, useEffect } from "react";
import styled from "@emotion/styled";
import useHomepageHeroTwoData from "../../hooks/homepage-hero-two-data";
import Img from "gatsby-image";
import { theme } from "../../utils/global-styles/theme";
import { _post } from "../../utils/services/api.service";
import HomePageHeroNewsLetterForm from "./HomePageHeroNewsLetterForm";
import HomepageHeaderLinks from "./HomepageHeaderLinks";
import HomepageBenefits from "./HomepageBenefits";

const HomePageHeroSectionTwo = () => {
  const homepageHeroData = useHomepageHeroTwoData(),
    Wrapper = styled.section`
      display: block;
      margin-bottom: 3rem;
    `,
    HeroContainer = styled.div`
      display: flex;
      flex-direction: column;
      position: relative;

      @media only screen and (min-width: ${theme.breakpoints.iPadPro}) {
        flex-direction: row;
        justify-content: flex-start;
        margin-left: 80px;
        gap: 20px;
      }
    `,
    ImageWrapper = styled.div`
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      border-radius: 38px;
      overflow: hidden;
      z-index: 1;
    `,
    Content = styled.div`
      position: relative;
      z-index: 2;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      height: 390px;

      @media only screen and (min-width: ${theme.breakpoints.iPadPro}) {
        height: 100%;
      }
    `,
    HeroImage = styled(Img)`
      display: block;
      width: 100%;
      height: 100%;
    `,
    Title = styled.h1`
      color: white;
      font-size: 45px;
      letter-spacing: -0.23px;
      line-height: 50px;

      @media only screen and (min-width: ${theme.breakpoints.iPadPro}) {
        margin-bottom: 151px;
        width: 70%;
      }
    `,
    TopContentWrapper = styled.div`
      display: block;
      position: relative;
      z-index: 2;
      border-radius: 39px;
      margin-right: 40px;
      margin-left: 40px;
      padding: 19px 40px;

      @media only screen and (min-width: ${theme.breakpoints.iPadPro}) {
        position: relative;
        width: 50%;
        margin-right: 0px;
        margin-left: 0px;
        padding: 40px;
      }
    `,
    Subtitle = styled.h2`
      color: white;
      margin-top: 11px;
      margin-bottom: 15px;
      font-size: 17px;
      line-height: 22px;
      letter-spacing: -0.26px;
      font-weight: normal;

      @media only screen and (min-width: ${theme.breakpoints.iPadPro}) {
        font-size: 17px;
        line-height: 22px;
        margin-bottom: 8px;
      }
    `,
    CtaButton = styled.div`
      display: inline-block;
      font-size: 12px;
      font-weight: bold;
      cursor: pointer;
      color: ${theme.colors.white};

      @media only screen and (min-width: ${theme.breakpoints
          .tabletLandscapeUp}) {
        font-size: 20px;
      }
    `,
    StyledCtaButton = styled(CtaButton)`
      position: relative;
      display: inline-block;
      text-decoration: none;

      &::before,
      &::after {
        content: "";
        position: absolute;
        top: 23px;
        height: 2px;
        width: 0;
        background-color: white;
        transition: width 0.2s ease-out;
      }

      &::before {
        left: 50%;
        transform: translateX(-100%);
      }

      &::after {
        right: 50%;
        transform: translateX(100%);
      }

      &:hover::before,
      &:hover::after {
        width: 50%;
      }
    `,
    LinksContainer = styled.div`
      display: none;
      overflow: hidden;
      flex-direction: column;
      justify-content: center;
      transform: translateY(3%);
      transition: transform 300ms ease-in-out, opacity 0.3s ease;

      @media only screen and (min-width: ${theme.breakpoints.iPadPro}) {
        display: flex;
      }
    `,
    LinksTitle = styled.h1`
      display: block;
      z-index: 2;
      font-size: 25px;

      @media only screen and (min-width: ${theme.breakpoints.iPadPro}) {
        font-size: 40px;
        letter-spacing: -1.23px;
        line-height: 40px;
        margin-bottom: 30px;
      }
    `,
    LinksTitleAndIcon = styled.div`
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-left: 40px;
      margin-right: 40px;
      justify-content: space-between;
      border-bottom: solid 1px ${theme.colors.main};

      @media only screen and (min-width: ${theme.breakpoints.iPadPro}) {
        border-bottom: none;
        margin-left: 0px;
      }
    `,
    ListItemWrapper = styled.div`
      font-size: 0.938rem;
      display: flex;
      align-items: center;
      width: 100%;
      font-weight: bold;
      cursor: pointer;
      font-family: AvantGardeDemi, sans-serif;
      padding: 1.4rem 1rem;
      border-bottom-style: solid;
      border-bottom-width: 0.031rem;
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      @media only screen and (min-width: ${theme.breakpoints.iPadPro}) {
        font-size: 1.311rem;
        width: 36.375rem;
        position: relative;
      }
    `,
    LinkText = styled.div`
      color: white;
      font-size: 0.688rem;
      cursor: pointer;

      @media only screen and (min-width: ${theme.breakpoints.iPadPro}) {
        font-size: 1.2rem;
      }
    `,
    HeroWrapper = styled.div`
      display: block;

      @media only screen and (min-width: 599px) {
        display: flex;
        flex-direction: column;
      }
    `,
    DesktopContentWrapper = styled.div`
      display: flex;
      flex-direction: column;
      margin-bottom: 44px;
      color: black;

      @media (min-width: 700px) and (max-width: ${theme.breakpoints.iPadPro}) {
        margin-top: 55px;
      }

      @media only screen and (min-width: ${theme.breakpoints.iPadPro}) {
        width: 50%;
      }
    `,
    PlayIconAndText = styled.div`
      display: flex;
      align-items: center;
      align-content: center;
      cursor: pointer;
    `,
    PlayIcon = styled.div`
      margin-right: 10px;
    `,
    DownIcon = styled.div`
      @media only screen and (min-width: ${theme.breakpoints.iPadPro}) {
        display: none;
      }
    `;

  const PlaySection = styled.div`
    position: absolute;
    bottom: 0;
  `;

  const ReadMoreDesktop = styled.a`
    color: black;
    display: none;
    cursor: pointer;
    font-size: 19px;
    margin: 0 auto;
    margin-top: 20px;
    margin-bottom: 28px;
    text-decoration: underline;
    text-align: center;

    @media only screen and (min-width: ${theme.breakpoints.desktopUp}) {
      text-decoration: none;
      display: inline-block;
      float: left;
      margin-left: 65%;
      margin-bottom: 100px;

      position: relative;

      &::before,
      &::after {
        content: "";
        position: absolute;
        top: 23px;
        height: 1px;
        width: 0;
        background-color: black;
        transition: width 0.2s ease-out;
      }

      &::before {
        left: 50%;
        transform: translateX(-100%);
      }

      &::after {
        right: 50%;
        transform: translateX(100%);
      }

      &:hover::before,
      &:hover::after {
        width: 50%;
      }
    }
  `;

  const {
      title,
      subtitle,
      leftSideCta: { url, linkText },
      linkListTitle,
      linkList,
      newsletterTitle,
      emailPlaceholder,
      newsletterButtonText,
      heroBenefits,
      linkListCta,
    } = homepageHeroData,
    redirectTo = url => {
      window.location.href = url;
    };

  const toggleMobileLinks = () => {
    const linksContainer = document.querySelector(".links-container");
    const readMoreButton = document.querySelector(".read-more-button");
    if (
      linksContainer.style.display === "none" ||
      linksContainer.style.display === ""
    ) {
      linksContainer.style.display = "flex";
      readMoreButton.style.display = "block";
    } else {
      linksContainer.style.display = "none";
      readMoreButton.style.display = "none";
    }
  };

  return (
    <Wrapper>
      <HeroWrapper>
        <HeroContainer className="pale-white-bg">
          <TopContentWrapper>
            <ImageWrapper className="pale-white-bg">
              <HeroImage
                fluid={homepageHeroData?.heroImage?.image.fluid}
                loading="eager"
              />
            </ImageWrapper>
            <Content>
              <Title>{title}</Title>
              <PlaySection>
                <Subtitle>{subtitle}</Subtitle>
                {url && (
                  <PlayIconAndText>
                    <PlayIcon>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="40"
                        height="40"
                        viewBox="0 0 40 40"
                      >
                        <path
                          id="Path_984"
                          data-name="Path 984"
                          d="M18.212,27.373l9.133-5.82A1.286,1.286,0,0,0,28,20.409a1.253,1.253,0,0,0-.651-1.13l-9.133-5.82a1.285,1.285,0,0,0-1.353-.025,1.24,1.24,0,0,0-.668,1.162v11.64a1.24,1.24,0,0,0,.668,1.162A1.285,1.285,0,0,0,18.212,27.373Zm2.724,13.043a19.539,19.539,0,0,1-7.774-1.562A20.1,20.1,0,0,1,2.479,28.179,19.539,19.539,0,0,1,.917,20.4a19.378,19.378,0,0,1,1.562-7.761A20.17,20.17,0,0,1,13.154,1.979,19.538,19.538,0,0,1,20.938.417,19.378,19.378,0,0,1,28.7,1.978,20.2,20.2,0,0,1,39.354,12.641,19.41,19.41,0,0,1,40.917,20.4a19.539,19.539,0,0,1-1.562,7.774A20.13,20.13,0,0,1,28.692,38.854,19.41,19.41,0,0,1,20.936,40.417Zm-.019-2.857a16.532,16.532,0,0,0,12.143-5,16.532,16.532,0,0,0,5-12.143,16.532,16.532,0,0,0-5-12.143,16.532,16.532,0,0,0-12.143-5,16.532,16.532,0,0,0-12.143,5,16.532,16.532,0,0,0-5,12.143,16.532,16.532,0,0,0,5,12.143A16.532,16.532,0,0,0,20.917,37.559Z"
                          transform="translate(-0.917 -0.417)"
                          fill="#fff"
                        />
                      </svg>
                    </PlayIcon>

                    <StyledCtaButton
                      onClick={() => {
                        redirectTo(url);
                      }}
                    >
                      {linkText}
                    </StyledCtaButton>
                  </PlayIconAndText>
                )}
              </PlaySection>
            </Content>
          </TopContentWrapper>

          <DesktopContentWrapper>
            <LinksTitleAndIcon>
              <LinksTitle>{linkListTitle}</LinksTitle>
              <DownIcon onClick={() => toggleMobileLinks()}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="9.407"
                  height="5.458"
                  viewBox="0 0 9.407 5.458"
                >
                  <g
                    id="Group_130"
                    data-name="Group 130"
                    transform="translate(14.698 -7.229) rotate(90)"
                  >
                    <path
                      id="Path_985"
                      data-name="Path 985"
                      d="M10.875,10,7.458,6.583A.761.761,0,0,1,7.99,5.292a.741.741,0,0,1,.538.236l3.951,3.951a.782.782,0,0,1,.156.244.785.785,0,0,1,0,.563.69.69,0,0,1-.156.235L8.528,14.472A.693.693,0,0,1,8,14.7a.758.758,0,0,1-.521-.24.73.73,0,0,1,0-1.062Z"
                      fill="#007076"
                    />
                  </g>
                </svg>
              </DownIcon>
            </LinksTitleAndIcon>
            <LinksContainer className={"links-container"}>
              <HomepageHeaderLinks linkList={linkList} />
            </LinksContainer>
          </DesktopContentWrapper>
        </HeroContainer>
        {linkListCta && (
          <ReadMoreDesktop className="read-more-button" href={linkListCta?.url}>
            {linkListCta?.linkText}
          </ReadMoreDesktop>
        )}
      </HeroWrapper>
      <HomePageHeroNewsLetterForm
        newsletterTitle={newsletterTitle}
        emailPlaceholder={emailPlaceholder}
        newsletterButtonText={newsletterButtonText}
      />
      <HomepageBenefits benefits={heroBenefits} />
    </Wrapper>
  );
};

export default HomePageHeroSectionTwo;
